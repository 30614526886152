@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* BUGFIXES */
/* CB-1412 */
.MuiMenuItem-root.MuiButtonBase-root {
  display: -webkit-flex;
  display: flex;
  padding: 6px 16px;
}

.mentions_mentions__10l1Q {
  margin: 1em 0;
}

.mentions_mentions--singleLine__9xSwV .mentions_mentions__control__1Oz-s {
  display: inline-block;
  width: 130px;
}
.mentions_mentions--singleLine__9xSwV .mentions_mentions__highlighter__yea3z {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions_mentions--singleLine__9xSwV .mentions_mentions__input__dEUAt {
  padding: 1px;
  border: 2px inset;
}

.mentions_mentions--multiLine__2JPjm .mentions_mentions__control__1Oz-s {
  /* font-family: monospace; */
  /* font-size: 14pt; */
}
.mentions_mentions--multiLine__2JPjm .mentions_mentions__highlighter__yea3z {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 1em;
  line-height: 1.2em;
}
.mentions_mentions--multiLine__2JPjm .mentions_mentions__input__dEUAt {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
  border-radius: 4px;
}
.mentions_mentions__suggestions__list__2CkNd {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions_mentions__suggestions__item__NsWWH {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions_mentions__suggestions__item--focused__2x7ea {
  background-color: #cee4e5;
}

.mentions_mentions__mention__2vAqR {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

.mentions_dark_mentions__20Hto {
  margin: 1em 0;
}

.mentions_dark_mentions--singleLine__1I8fW .mentions_dark_mentions__control__3N-fo {
  display: inline-block;
  width: 130px;
}
.mentions_dark_mentions--singleLine__1I8fW .mentions_dark_mentions__highlighter__14uRO {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions_dark_mentions--singleLine__1I8fW .mentions_dark_mentions__input__PedWp {
  padding: 1px;
  border: 2px inset;
  color: #ffffff
}

.mentions_dark_mentions--multiLine__3PMgg .mentions_dark_mentions__control__3N-fo {
  /* font-family: monospace; */
  /* font-size: 14pt; */
}
.mentions_dark_mentions--multiLine__3PMgg .mentions_dark_mentions__highlighter__14uRO {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 1em;
  line-height: 1.2em;
}
.mentions_dark_mentions--multiLine__3PMgg .mentions_dark_mentions__input__PedWp {
  border: 1px solid silver;
  padding: 9px;
  color: #ffffff;
  outline: 0;
  border-radius: 4px;
}
.mentions_dark_mentions__suggestions__list__IjbNN {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions_dark_mentions__suggestions__item__3y6uh {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions_dark_mentions__suggestions__item--focused__Q-7UK {
  background-color: #cee4e5;
}

.mentions_dark_mentions__mention__1mf30 {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

